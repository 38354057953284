<template>
  <CRSelect
    :id="`${id}-multi-lead-temperature-type-filter-select-type-id`"
    v-model="leadTemperatureTypeSelections"
    :items="leadTemperatureTypes"
    chips
    class="radius-selector"
    flat
    hide-details
    item-text="label"
    item-value="leadTemperatureTypeId"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      leadTemperatureTypes: [],
      leadTemperatureTypeSelections: [],
      leadTemperatureTypeFilter: {
        column: {
          _t_id: 'e933e583-ca45-4da8-8aba-5e7c4cdd1fa5',
          prop: 'leadTemperatureTypeId',
          type: 'number',
          filterType: 'eq',
        },
      },
      activeLeadTemperatureTypeFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const resultList = await this.$store.dispatch(
      'types/getLeadTemperatureTypes',
      {}
    )
    this.leadTemperatureTypes = resultList.data.resultList
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter(e) {
      if (this.findFilter(this.activeLeadTemperatureTypeFilter)) {
        this.filters.remove(this.activeLeadTemperatureTypeFilter)
      }
      if (this.leadTemperatureTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addMarketLocationFilter()
        this.addFilter()
      }
      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addMarketLocationFilter() {
      const cloneLeadTemperatureTypeSelections = [].concat(
        this.leadTemperatureTypeSelections
      )
      const newFilter = deepClone(this.leadTemperatureTypeFilter)
      newFilter.hideOnFilterBar = true
      newFilter.value = cloneLeadTemperatureTypeSelections.join(' ')
      this.activeLeadTemperatureTypeFilter = newFilter
      newFilter.stepParent = this.column._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.radius-selector {
  width: 200px !important;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
}

.radius-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}
</style>
