<template>
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-layout column sheet>
      <h1 class="page-header">Quotes</h1>
      <DataTable v-bind.sync="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'

import DataTable from '@/components/DataTable.vue'
import QuotesListDetail from '@/components/QuotesListDetail.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import QuotesTableActions from '@/components/QuotesTableActions.vue'

import quotesActionsWrapper from '@/components/QuotesActionsWrapper'

import * as logger from '@/utils/logger'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import MultiLeadTemperatureTypeFilter from '@/components/MultiLeadTemperatureTypeFilter.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import { phoneFormatFilterWithInternational } from '@/utils/phone'
import { mapActions, mapGetters } from 'vuex'

import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  noPastDatesPredefined,
  numericRangePredefined,
  textLike,
  userPredefined,
  customerPredefined,
} from '@/utils/predefined'
import { v4 } from 'uuid'
import { EventBus } from '@/utils/event-bus'
import { SplitFeatureFlag } from '@/utils/enum'

const wrappedActions = quotesActionsWrapper(QuotesTableActions)

const quoteConversionStatusMap = [
  { text: 'Unconverted', value: 'false' },
  { text: 'Converted', value: 'true' },
]

const contractSignedStatusMap = [
  { text: 'Unsigned', value: 'false' },
  { text: 'Signed', value: 'true' },
]

const contractOpenStatusMap = [
  { text: 'Closed', value: 'false' },
  { text: 'Open', value: 'true' },
]

const leadTemperatureTypeMap = [
  { text: 'Hot', value: 1 },
  { text: 'Warm', value: 2 },
  { text: 'Cold', value: 3 },
]

let categories = [
  {
    _t_id: '1711fa30',
    text: 'Quote Status',
    prop: 'isConverted',
    values: deepClone(quoteConversionStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Contract Status',
    prop: 'contractIsSigned',
    values: deepClone(contractSignedStatusMap),
    type: 'text',
    method: 'or',
    group: 'contractStatus',
  },
  {
    _t_id: 'f884d1a7',
    text: 'Contract Open Status',
    prop: 'contractIsOpen',
    values: deepClone(contractOpenStatusMap),
    type: 'text',
    method: 'or',
    group: 'contractStatus',
  },
]

export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Quotes',
    }
  },
  data() {
    return {
      teamClassificationsMap: [],
      latestRequestId: null,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      categoryParentId: undefined,
      selectedCategory: undefined,
      itemsPerPage: 10,
      page: 1,
      isTiersEnabled: false,
      tableProps: {
        exportAction: 'quotes/quotesTableExport',
        exportName: 'quotes.csv',
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: true,
        canViewInactiveQuotes: false,
        isReportAdmin: false,
        tableId: 'quotes_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: QuotesListDetail,
        detailKeyId: 'quoteId',
        isDetailed: false,
        addNewEnabled: true,
        addNewHandler: () => {
          this.$router.push({ name: 'quotes.add' })
        },
        initialFilters: [],
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        calculatedValues,
        columns: [
          {
            _t_id: 'f4dfd342',
            prop: '/',
            text: 'Actions',
            component: wrappedActions,
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
            shrinkWidth: true,
          },
          {
            _t_id: 'f4dfd5e0',
            prop: 'quoteId',
            text: 'ID',
            component: QuotesActionableColumn,
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            displayType: 'clickable-link-new',
            filterType: 'eq',
          },
          {
            _t_id: 'f4dfd72a',
            prop: 'createdByName',
            text: 'Sent By',
            component: QuotesActionableColumn,
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            action: 'BOOKED_BY_DETAIL',
            displayType: 'action-item',
            sortProp: 'createdByName',
            filterType: 'contains',
            childMethod: 'and',
            predefined: userPredefined,
          },
          {
            _t_id: 'f4dfd856',
            prop: 'tripTypes',
            text: 'Trip Types',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            displayType: 'trip-type-multi',
            filterType: 'contains',
          },
          {
            _t_id: 'f4dfd978',
            prop: 'createdOn',
            text: 'Created On',
            computedText: (item) =>
              item
                ? DateTime.fromISO(item, {
                    zone:
                      this.currentUser?.timeZone ||
                      this.currentUser?.company?.address?.timeZone,
                  }).toFormat('MM/dd/yyyy t ZZZZ')
                : '',
            sort: true,
            filter: true,
            type: 'date',
            detail: false,
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(noFutureDatesPredefined),
            defaultSort: true,
          },
          {
            _t_id: 'f4dfdd06',
            prop: 'convertedOn',
            text: 'Converted On',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            type: 'date',
            detail: false,
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(noFutureDatesPredefined),
          },
          {
            _t_id: 'f4dfde64',
            prop: 'customerName',
            text: 'Customer',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            detail: false,
            displayType: 'action-item',
            filterType: 'contains',
            childMethod: 'and',
            predefined: deepClone(customerPredefined),
          },
          {
            _t_id: '0b53e2a7-952a-4c8b-911c-4c69a2829038',
            prop: 'customerAccountName',
            text: 'Customer Account',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            detail: false,
            displayType: 'action-item',
            filterType: 'contains',
            childMethod: 'and',
            defaultHidden: true,
            predefined: deepClone(textLike),
          },
          {
            _t_id: '1711f7ce',
            prop: 'amount',
            text: 'Amount',
            computedText: (item) => currencyFilter(item),
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            sortProp: 'amount',
            filterType: 'eq',
            childMethod: 'and',
            predefined: deepClone(numericRangePredefined),
          },
          {
            _t_id: '6b01a5c0',
            prop: 'distanceFromMarket',
            filterType: '',
            filterPlaceholder: true,
          },
          {
            _t_id: 'c22dbed8',
            prop: 'marketId',
            filterType: 'eq',
            filterPlaceholder: true,
          },
          {
            _t_id: '1eb6452a',
            prop: 'nearestMarketId',
            filterType: 'eq',
            filterPlaceholder: true,
          },
          {
            _t_id: 'adcdd422',
            prop: 'paymentTypes',
            text: 'Payment Types',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            displayType: 'payment-type-multi',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdd300',
            prop: 'paymentMethodTypes',
            text: 'Payment Methods',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            displayType: 'payment-method-type-multi',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: '7b3b2ee5',
            prop: 'vehicleTypes',
            text: 'Vehicle Types',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdd1ca',
            prop: 'checkoutPage/label',
            text: 'Checkout Page',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            sortProp: 'checkoutPage/key',
            displayType: 'res-multi',
            filterProp: 'checkoutPage/key',
            filterType: 'contains',
            defaultHidden: false,
          },
          {
            _t_id: 'adcdce3c',
            prop: 'leadSourceType/label',
            text: 'Lead Source',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            sortProp: 'leadSourceType/key',
            filterProp: 'leadSourceType/key',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdccfc',
            prop: 'reservationIds',
            text: 'Reservations',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            type: 'text',
            sortProp: 'trips/reservations/reservationId',
            detail: false,
            displayType: 'res-multi',
            filterProp: 'trips/reservations/reservationId',
            filterType: 'eq',
            defaultHidden: true,
          },
          {
            _t_id: 'b7bcbc26',
            prop: 'dropoffDate',
            text: 'Dropoff Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            detail: false,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            defaultHidden: true,
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'b7bcc3d8',
            prop: 'pickupDate',
            text: 'Pickup Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            detail: false,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            defaultHidden: true,
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'b7bcc554',
            prop: 'isConverted',
            text: 'Quote Status',
            computedText: (item) => (item ? 'Converted' : 'Unconverted'),
            sort: false,
            filter: true,
            detail: false,
            defaultHidden: true,
            customFilterTabDisplay: (item) =>
              item ? 'Converted' : 'Unconverted',
            filterType: 'eq',
            predefined: [
              {
                text: 'Converted',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'Unconverted',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
          },
          {
            _t_id: 'contract_id_filter_1',
            filterType: 'isnull',
            prop: 'contractId',
            type: 'number',
            filterPlaceholder: true,
          },
          {
            _t_id: 'b4cbaea7-d311-4c81-84b0-e5f31f2acd9c',
            prop: 'firstPickupAddress',
            text: 'First Pickup Address',
            sort: true,
            filter: true,
            type: 'text',
            method: 'and',
            sortProp: 'firstPickupAddressName',
            filterProp: 'firstPickupAddressName',
            childMethod: 'and',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: '28454133-b085-48e4-9be9-18f79bd05cf7',
            prop: 'firstDropoffAddress',
            text: 'First Dropoff Address',
            sort: true,
            filter: true,
            type: 'text',
            method: 'and',
            sortProp: 'firstDropoffAddressName',
            filterProp: 'firstDropoffAddressName',
            childMethod: 'and',
            filterType: 'contains',
            defaultHidden: true,
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isAccountExecutiveAndOpportunityIdEnabled:
        'featureToggles/isAccountExecutiveAndOpportunityIdEnabled',
      tiersTVMap: 'tiers/getTiersTVMap',
      predefinedTiersTVMap: 'tiers/getPredefinedTiersTVMap',
    }),
  },
  async mounted() {
    this.tableProps.canViewInactiveQuotes = this.canViewInactiveQuotes()
    this.tableProps.isReportAdmin = this.isReportAdmin()
    this.tableProps.enableExport = this.canExport()
    this.tableProps.categories = deepClone(categories)

    if (this.canViewCallerIDAndPhoneNumber()) {
      this.tableProps.columns.push(
        {
          _t_id: '62787a30',
          text: 'Phone Number',
          prop: 'phoneNumber',
          computedText: (item) =>
            phoneFormatFilterWithInternational(item, true),
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'b2ad0d5f',
          text: 'Caller ID',
          prop: 'callerId',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
        }
      )
    }

    this.isTiersEnabled = await this.isFeatureEnabled(SplitFeatureFlag.ServiceTier)
    if (this.isTiersEnabled) {
      await this.fetchAllTiers()
      this.initializeTiersTVSupport()
    }

    if (this.currentUser?.company?.companyType?.companyTypeId === 2) {
      this.tableProps.columns = this.tableProps.columns.filter((col) => {
        if (col._t_id === 'adcdd1ca') {
          return false
        }
        if (col._t_id === 'adcdce3c') {
          return false
        }
        return true
      })
    }

    this.tableProps.columns.push(
      {
        _t_id: 'ad83ffd7-f91a-4645-83de-90ff16078531',
        prop: 'leadTemperatureTypeId',
        text: 'Lead Temp',
        computedText: (item) => {
          const leadTemp = leadTemperatureTypeMap.find(
            (leadTemp) => leadTemp.value === item
          )
          return leadTemp?.text || ''
        },
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        filterType: 'eq',
        predefined: [
          {
            text: 'Select Temperatures',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            controls: [
              {
                id: 'aa1253fc-b744-4dbb-9dcd-153cd7a654d9',
                text: 'Select Temperatures',
                filterType: 'contains',
                component: MultiLeadTemperatureTypeFilter,
              },
            ],
          },
        ],
        defaultHidden: true,
      },
      {
        _t_id: '5045057a-5100-4d7b-99c6-f4dc84a8a81f',
        prop: 'leadFollowUpDate',
        text: 'Next Follow Up Date',
        computedText: (item) =>
          item === null
            ? ''
            : DateTime.fromISO(item, { zone: 'utc' }).toFormat('MM/dd/yyyy'),
        sort: true,
        filter: true,
        detail: false,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        defaultHidden: true,
        predefined: deepClone(noPastDatesPredefined),
      },
      {
        _t_id: '6bd300e0-6623-4be8-9a11-98daa232f8b7',
        prop: 'productClassificationLabel',
        text: 'Product',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'contains',
        predefined: textLike,
        defaultHidden: true,
      },
      {
        _t_id: 'f534e6c0-8e54-4531-8bc3-575f30b1afc5',
        prop: 'sourcingTeamClassificationLabel',
        text: 'Sourcing Team',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'contains',
        predefined: textLike,
        defaultHidden: true,
      },
      {
        _t_id: '020f5f3a-f456-4361-b885-441d9d7793cc',
        prop: 'supportTeamClassificationLabel',
        text: 'Support Team',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'contains',
        predefined: textLike,
        defaultHidden: true,
      }
    )

    if (this.isTiersEnabled) {
      this.tableProps.columns.push(
        {
          _t_id: '37f4ac85-4a1d-4c94-80d1-dcecae1e4f3e',
          prop: 'tierId',
          text: 'Tier',
          computedText: (item) => this.mapCategoryText(this.tiersTVMap, item),
          sort: true,
          filter: true,
          detail: false,
          filterType: 'eq',
          defaultHidden: false,
          predefined: this.predefinedTiersTVMap,
        }
      )
    }

    if (this.tableProps.canViewInactiveQuotes) {
      this.tableProps.columns.push({
        _t_id: '72b5b00c-5169-48d6-93bc-9b1f2908d6db',
        prop: 'active',
        text: 'Active',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: false,
        filter: true,
        detail: false,
        defaultHidden: true,
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        filterType: 'eq',
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      })
    }

    this.tableProps.columns.push(
      {
        _t_id: 'xb7bcc554',
        prop: 'isCharterUpQuote',
        text: 'Marketplace',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        detail: false,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: '1a25db55',
        prop: 'pickupLocation',
        text: 'Pickup Location',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        filterType: 'contains',
        childMethod: 'or',
        unset: ['6b01a5c0', 'c22dbed8'],
        predefined: [
          {
            text: 'Radius',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            controls: [
              {
                id: '9522531a',
                text: 'Location Radius',
                filterType: 'radius',
                component: PickupRadiusFilter,
              },
            ],
          },
          {
            text: 'Multiple Markets',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            controls: [
              {
                id: '37e10ab1',
                text: 'Multiple Markets',
                filterType: 'contains',
                component: MultiMarketFilterVue,
              },
            ],
          },
          {
            text: 'Search',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            controls: [
              {
                text: 'Search',
                filterType: 'contains',
              },
            ],
          },
        ],
      },
      {
        _t_id: 'b8315ae8',
        prop: 'isConfirmed',
        text: 'Confirmed',
        computedText: (item, row) => {
          if (item) {
            return 'Yes'
          }
          if (row.isCharterUpQuote) {
            return item ? 'Yes' : 'No'
          }
          return ''
        },
        sort: true,
        filter: true,
        filterType: 'eq',
        detail: false,
        type: 'text',
        sortProp: 'isConfirmed',
        filterProp: 'isConfirmed',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: '095885d2',
        prop: 'isPreBooking',
        text: 'Pre-Booking',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        detail: false,
        type: 'text',
        sortProp: 'isPreBooking',
        filterProp: 'isPreBooking',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: 'f4d9de64',
        prop: ['customer/organization'],
        text: 'Organization',
        sort: true,
        filter: true,
        type: 'text',
        detail: false,
        sortProp: 'customer/organization',
        filterType: 'contains',
        childMethod: 'and',
        defaultHidden: true,
        predefined: textLike,
      },
      {
        _t_id: 'b7bc93d8',
        prop: 'decisionDate',
        text: 'Decision Date',
        computedText: (item) =>
          item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
        sort: true,
        filter: true,
        detail: false,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        defaultHidden: true,
        predefined: deepClone(datePredefined),
      },
      {
        _t_id: 'b45c398dc8ed4cd6a36b3648023f5fbd',
        prop: 'customer/industries',
        text: 'Industries',
        computedText: (item, row) => (item ? item.replaceAll('||', ' / ') : ''),
        sort: true,
        sortProp: 'customer/industries',
        filterProp: 'customer/industries',
        filter: true,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'contains',
        defaultHidden: true,
      },
      {
        _t_id: 'qqhpvu7wdc',
        prop: ['customer/industryDescription'],
        text: 'Industry Description',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        filterType: 'contains',
        sortProp: 'trip.customer.industryDescription',
        filterProp: 'trip.customer.industryDescription',
        defaultHidden: true,
      },
      {
        _t_id: 'b7bk93d8',
        prop: 'participatingInEmailCampaigns',
        text: 'Email Campaign Enabled',
        computedText: (item) => (item ? 'Enabled' : 'Disabled'),
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        defaultHidden: true,
        customFilterTabDisplay: (item) => (item ? 'Enabled' : 'Disabled'),
        predefined: [
          {
            text: 'Enabled',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'Disabled',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: 'd15d2ba9',
        prop: 'contractName',
        text: 'Contract',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: textLike,
      }
    )
    this.tableProps.columns.push(
      {
        _t_id: 'd0ea72c4-554f-4eec-810d-1bfbd9950a7f',
        prop: 'soldOut',
        text: 'Sold Out',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        detail: false,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
      {
        _t_id: '31667698-4946-40d2-aeb3-d59657ee2c70',
        prop: 'isPendingConfirmation',
        text: 'Pending Confirmation',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        detail: false,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Pending Confirmation',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            checkbox: true,
            value: 1,
            customEval: (activeFilter) => {
              if (!activeFilter.value) {
                activeFilter.value = true
              } else {
                activeFilter.value = undefined
              }
            },
          },
        ],
      },
      {
        _t_id: 'a0f0ef10-24c5-427d-ac16-756c9c5fdb01',
        prop: 'isEnterprise',
        text: 'Enterprise',
        computedText: (item) => (item ? 'Yes' : 'No'),
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        defaultHidden: true,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      }
    )

    if (this.isAccountExecutiveAndOpportunityIdEnabled) {
      this.tableProps.columns.push(
        {
          _t_id: '12b94734-7d91-11ee-b962-0242ac120002',
          prop: 'accountExecutiveName',
          text: 'Account Executive',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          sortProp: 'accountExecutiveName',
          filterProp: 'accountExecutiveName',
          childMethod: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '176ed6c2-7d91-11ee-b962-0242ac120002',
          prop: 'opportunityId',
          text: 'Opportunity ID',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          sortProp: 'opportunityId',
          filterProp: 'opportunityId',
          childMethod: 'and',
          filterType: 'contains',
          defaultHidden: true,
        }
      )
    }

    EventBus.$on('refresh-quotes', () => {
      this.refresh()
    })
  },

  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
      fetchAllTiers: 'tiers/fetchAllTiers',
      initializeTiersTVSupport: 'tiers/initializeTiersTVSupport',
    }),
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.quoteId !== targetRow.quoteId
      )
    },
    canViewInactiveQuotes() {
      const roles = this.currentUserProfile?.roles || []
      const canViewInactiveQuotes = roles.find(
        (r) => r.roleName === 'can_view_inactive_reservations_and_quotes'
      )
      return !!canViewInactiveQuotes
    },
    canViewCallerIDAndPhoneNumber() {
      const roles = this.currentUserProfile?.roles || []
      const canViewCallerIDAndPhoneNumber = roles.find(
        (r) => r.roleName === 'can_view_caller_id_and_phone_number'
      )
      return !!canViewCallerIDAndPhoneNumber
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportQuotes = roles.find(
        (r) => r.roleName === 'can_export_quotes'
      )
      return !!canExportQuotes
    },
    isReportAdmin() {
      const roles = this.currentUserProfile?.roles || []
      const isReportAdmin = roles.find((r) => r.roleName === 'is_report_admin')
      return !!isReportAdmin
    },
    displayAllIndustries(row) {
      if (!row.customer.industry) return ''
      var industries = row.customer.industry.label
      var currentIndustry = Object.assign({}, row.customer.industry)

      while (currentIndustry.parentIndustryId) {
        industries = currentIndustry.parentIndustry.label + ' / ' + industries
        currentIndustry = Object.assign({}, currentIndustry.parentIndustry)
      }

      return industries
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const { company } = this.currentUser
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        companyId: company.companyId,
        quotes: 'company',
      }
      this.tableProps.loading = true
      const quoteData = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })

      if (this.latestRequestId !== requestId) {
        return
      }

      if (quoteData) {
        this.tableProps.perPage = this.itemsPerPage
        this.tableProps.currentPage = this.currentPage
        this.tableProps.list = quoteData.data.resultList
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
        this.tableProps.total = quoteData.data.count
        this.tableProps.loading = false
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  cursor: pointer;
}
</style>
<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}
</style>
